<template>
  <div>
    <v-card tile flat>
      <v-alert :value="loading"
        >{{ $vuetify.lang.t(loadingText) }}<inline-loader :value="true"></inline-loader
      ></v-alert>

      <v-alert dark
        :value="!loading"
        v-for="({ key, text, color, goTo }, i) in alerts"
        :key="`alert-${key}-${i}`"
        :color="color || 'warning'"
      >
        <div v-html="$vuetify.lang.t(text)"></div>

        <p v-if="showRedirectLoader && goTo">{{ $vuetify.lang.t('$vuetify.linkHandlerModule.redirect_info') }}</p>
        <timer
          v-if="showRedirectLoader && goTo"
          :value="redirectTime"
          color="purple"
          indeterminate
          @timer:finish="$router.push(goTo.to)"></timer>

        <p v-if="showRedirectLoader && goTo">{{ $vuetify.lang.t('$vuetify.linkHandlerModule.redirect_info_or') }}</p>
        <v-btn v-if="goTo" :to="goTo.to">{{ $vuetify.lang.t(goTo.text) }}</v-btn>
      </v-alert>
    </v-card>

    <v-card dark v-if="!isProduction">
      <pre><code>{{ $vuetify.lang.t('Input:') }} {{ $props }}</code></pre>
      <pre><code>{{ $vuetify.lang.t('Output:') }} {{ verificationResponse }}</code></pre>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ErrorType, WsErrorType } from '../../../constants';
import { Services } from '../../../store/actions/api';
import Timer from './Timer.vue';
import InlineLoader from './InlineLoader.vue';
import { captureException } from '@sentry/vue';

export default {
  name: 'LinkHandlerModule',

  inject: ['isProduction', 'debug'],

  props: {
    email: {
      type: String,
    },
    token: {
      type: String,
    },
    accept: {
      type: Number,
      default: 0,
    },
    loadingText: {
      type: String,
      default: '$vuetify.alerts.verification_in_progress',
    },
    redirectTime: {
      type: Number,
      default: 10,
    },
  },

  components: {
    Timer,
    InlineLoader,
  },

  data() {
    return {
      enabledProduction: this.isProduction,
      enabledDebug: this.debug,
      verificationResponse: null,
      alerts: [],
    };
  },

  computed: {
    // check auth status
    ...mapGetters(['isAuthorized']),
    loading() {
      return this.$store.state.linkHandler.loading;
    },

    showRedirectLoader() {
      return !this.enabledDebug && this.enabledProduction && this.redirectTime;
    }
  },

  methods: {
    defaultRedirect() {
      if (this.isAuthorized) {
        return { text: '$vuetify.linkHandlerModule.btn_go_back', to: process.env.VUE_APP_DEFAULT_REDIRECT };
      }
      return { text: '$vuetify.linkHandlerModule.btn_goto_login', to: '/' };
    },

    addAlert({ text, color = 'success', goTo }) {
      if (!goTo) {
        goTo = this.defaultRedirect();
      }

      this.alerts.push({
        goTo,
        text,
        color
      });
    },

    handleSuccess(data) {
      try {
        let resp = data;

        if (typeof resp === 'string') {
          resp = JSON.parse(resp);
        }

        let context = resp.sourceRequest;

        switch (context) {
          case Services.FlightScopeUser.methods.InsertUser.key:
            if (resp.isAccepted) {
              const text = '$vuetify.alerts.user_verification_pos';
              this.addAlert({ text });
            } else {
              const text = '$vuetify.alerts.request_denied';
              this.addAlert({ text, color: 'info' });
            }
            break;

          case Services.FlightScopeUser.methods.RequestPasswordReset.key:
            if (resp.isAccepted) {
              // redirect to password recovery form
              this.$router.push({
                name: 'account.passwordRecovery',
                params: {
                  email: this.email,
                  token: this.token,
                },
              });
            } else {
              const text = '$vuetify.alerts.request_denied';
              this.addAlert({ text, color: 'info' });
            }
            break;

          case Services.TeamManagement.methods.SendFollowIntent.key:
            if (resp.isAccepted) {
              const text = 'You\'ve approved the tracking request.';
              this.addAlert({ text });
            } else {
              const text = 'You\'ve denied the tracking request.';
              this.addAlert({ text, color: 'info' });
            }
            break;

          default:
            this.$log.debug('Link Handler Default Case - Just Logging', data);
        }
      } catch (error) {
        captureException(error);
        this.$log.debug('Link Handler Error Case - Just Logging', error);
      }
    },

    handleError(type, message) {
      switch (type) {
        case ErrorType.SERVICE_ERROR:
        case ErrorType.WS_ERROR:
          {
            let [key, text] = message.split(': ');
            this.$log.debug(key, text);
            switch (key) {
              case WsErrorType.RECORD_EXIST:
                this.addAlert({ text: '$vuetify.alerts.link_expired', color: 'info' });
                break;
              default:
              // noop
            }
          }
          break;
        case ErrorType.INPUT_INVALID:
        default:
          this.$log.debug('LOCO', type, message);
          this.addAlert({ text: '$vuetify.alerts.request_error', key: 'invalid', color: 'info' });
      }
    },
  },

  async mounted() {
    const resp = await this.$store.dispatch('linkHandler/verify', this.$props);

    let { type, data, message } = resp;

    if (type === 'OK') {
      if (data) {
        this.handleSuccess(data);
      } else {
        this.addAlert({ text: '$vuetify.alerts.response_not_readable', color: 'warning' });
      }
    } else {
      this.handleError(type, message);
    }

    this.verificationResponse = resp;
  },
};
</script>
